<template>
    <div id="contactUs" :class="{'extended' : isVisible}">
        <span v-on:click="isVisible = !isVisible" id="contacUsTitle">
            <p v-if="isVisible" class="medium">-</p><p v-else class="medium">+</p><span style="width: 5px;"/>
            <p class="medium">CONTACT US</p>
        </span>
        <div class="contact-label">
            <div>
                <p class="s-medium">Phone</p>
                <a class="s-medium" href="tel:+390280896815">+39 02 80 896 815</a>
            </div>
            <div>
                <p class="s-medium">E-mail</p>
                <a class="s-medium" href="mailto:hello@ppkinnovation.com">hello@ppkinnovation.com</a>    
            </div>
            <div>
                <p class="s-medium">Linkedin</p>
                <a class="s-medium" href="https://www.linkedin.com/company/ppkinnovation/">@ppkinnovation</a>
            </div>
            <div>
                <p class="s-medium">Whatsapp</p>
                <a class="s-medium" href="https://wa.me/+393516256786">@+39 351 625 6786</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            isVisible: false
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/style/styles.scss';
div#contactUs{
    position: fixed; 
    bottom: 40px;
    left: 30px;
    z-index: 12;
    transition: max-height .5s;
    overflow-y: hidden;
    width: 225px;
    max-height: 50px;
    p, a{
        background-color: $color-gray;
    }
    #contacUsTitle{
        cursor: pointer;
        height: 50px;
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        display: flex;
        align-items: center;
    }
    .contact-label{
        height: calc(100% - 50px);
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
        row-gap: 5px;
        div{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            p{
                margin: 2px 0;
            }
        }
    }
}
div#contactUs.extended{
    max-height: 240px;
    height: 100%;
    transition: max-height .5s;
}
#app.yellow-theme{
    p, a{
        background-color: $color-yellow;
    }
}
//tablet version
@media screen and (max-width: 1200px){
    #contactUs.extended{
        max-height: 225px !important;
    }
}
</style>