<template>
    <div>
        <div class="page">
            <div v-for="(el, index) in project" :key="index" class="project-title-box">
                <p class="xx-large italic">
                    <a v-if="el.url" :href="el.url" target="_blanck">{{ el.title.name }}</a>
                    <span v-else target="_blanck">{{ el.title.name }}</span>
                </p>
                <p class="project-subtitle muted">
                    <span v-for="(subel, subind) in el.subtitle" :key="subind">{{ subel.name }} </span>
                </p>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Footer from '@/components/Footer'
export default {
    components: {
        Footer
    },
    data() {
        return {
            project: [
                {
                    title: {
                        name: "Tutelio",
                        component: ""
                    },
                    url: "https://tutelio.org/",
                    subtitle: [
                        {
                            name: "#Business design",
                            component: ""
                        },
                        {
                            name: "#Front-end development",
                            component: ""
                        },
                        {
                            name: "#Back-end development",
                            component: ""
                        },
                        {
                            name: "#Marketing",
                            component: ""
                        },
                        {
                            name: "#Management",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "Kiwi Data Science",
                        component: ""
                    },
                    url: "https://www.kiwidatascience.com/",
                    subtitle: [
                        {
                            name: "#PPKFormula",
                            component: ""
                        },
                        {
                            name: "#Strategy",
                            component: ""
                        },
                        {
                            name: "#USP",
                            component: ""
                        },
                        {
                            name: "#Channels",
                            component: ""
                        },
                        {
                            name: "#Adv",
                            component: ""
                        },
                        {
                            name: "#Finance",
                            component: ""
                        }, 
                        {
                            name: "#Businessdevelopment",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "Reload",
                        component: ""
                    },
                    url: "https://reload.it/",
                    subtitle: [
                        {
                            name: "#PPKFormula",
                            component: ""
                        },
                        {
                            name: "#Strategy",
                            component: ""
                        },
                        {
                            name: "#USP",
                            component: ""
                        },
                        {
                            name: "#Channels",
                            component: ""
                        },
                        {
                            name: "#Adv",
                            component: ""
                        },
                        {
                            name: "#Finance",
                            component: ""
                        }, 
                        {
                            name: "#Businessdevelopment",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "Politecnico di Milano",
                        component: ""
                    },
                    url: "https://www.polimi.it/",
                    subtitle:[
                        {
                            name: "#Communication",
                            component: ""
                        },
                        {
                            name: "#Video",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "Gayadeed",
                        component: ""
                    },
                    url: "https://www.gayadeed.it/it/",
                    subtitle: [
                        {
                            name: "#Front-end development",
                            component: ""
                        },
                        {
                            name: "#UX/UI design",
                            component: ""
                        },
                        {
                            name: "#Service design",
                            component: ""
                        },
                        {
                            name: "#Marketing",
                            component: ""
                        },
                        {
                            name: "#Brand image",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "Apphotels.com",
                        component: ""
                    },
                    url: "https://apphotels.com/",
                    subtitle: [
                        {
                            name: "#PPKFormula",
                            component: ""
                        },
                        {
                            name: "#Strategy",
                            component: ""
                        },
                        {
                            name: "#USP",
                            component: ""
                        },
                        {
                            name: "#Channels",
                            component: ""
                        },
                        {
                            name: "#Adv",
                            component: ""
                        },
                        {
                            name: "#Finance",
                            component: ""
                        }, 
                        {
                            name: "#Businessdevelopment",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "Cellulariusati.net",
                        component: ""
                    },
                    url: "https://cellulariusati.net/",
                    subtitle:[
                        {
                            name: "#PPKFormula",
                            component: ""
                        },
                        {
                            name: "#Strategy",
                            component: ""
                        },
                        {
                            name: "#USP",
                            component: ""
                        },
                        {
                            name: "#Product",
                            component: ""
                        },
                        {
                            name: "#CRO",
                            component: ""
                        },
                        {
                            name: "#Adv",
                            component: ""
                        },
                        {
                            name: "#Finance",
                            component: ""
                        }, 
                        {
                            name: "#Businessdevelopment",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "Intellera consulting",
                        component: ""
                    },
                    url: "https://www.intelleraconsulting.com/",
                    subtitle: [
                        {
                            name: "#Communication",
                            component: ""
                        },
                        {
                            name: "#Video",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "HiJef!",
                        component: ""
                    },
                    url: "",
                    subtitle: [
                        {
                            name: "#Businessdesign",
                            component: ""
                        },
                        {
                            name: "#Back-end",
                            component: ""
                        },
                        {
                            name: "#Front-end",
                            component: ""
                        },
                        {
                            name: "#Corporateidentity",
                            component: ""
                        },
                        {
                            name: "#UX/UI",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "TIM",
                        component: ""
                    },
                    url: "https://www.tim.it",
                    subtitle: [
                        {
                            name: "#Front-end development",
                            component: ""
                        },
                        {
                            name: "#UX/UI Restyling",
                            component: ""
                        }
                    ]
                },
                {
                    title:{
                        name: "Intarget group",
                        component: ""
                    },
                    url: "",
                    subtitle: [
                        {
                            name: "#Openinnovation",
                            component: ""
                        },
                        {
                            name: "#SWdevelopment",
                            component: ""
                        },
                        {
                            name: "#Productdesign",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "Uno di un milione",
                        component: ""
                    },
                    url: "https://unodiunmilione.com/",
                    subtitle: [
                        {
                            name: "#Publishing",
                            component: ""
                        },
                        {
                            name: "#Web-app development",
                            component: ""
                        },
                        {
                            name: "#Front-end development",
                            component: ""
                        },
                        {
                            name: "#Back-end development",
                            component: ""
                        },
                        {
                            name: "#Marketing",
                            component: ""
                        },
                        {
                            name: "#Brand immage",
                            component: ""
                        },
                        {
                            name: "#Product design",
                            component: ""
                        },
                        {
                            name: "#Service design",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "Multiwin",
                        component: ""
                    },
                    url: "https://www.multiwin.it/",
                    subtitle: [
                        {
                            name: "#PPKFormula",
                            component: ""
                        },
                        {
                            name: "#Strategy",
                            component: ""
                        },
                        {
                            name: "#USP",
                            component: ""
                        },
                        {
                            name: "#Channels",
                            component: ""
                        },
                        {
                            name: "#Adv",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "Brandoh!",
                        component: ""
                    },
                    url: "https://www.brandoh.app/",
                    subtitle: [
                        {
                            name: "#PPKFormula",
                            component: ""
                        },
                        {
                            name: "#Strategy",
                            component: ""
                        },
                        {
                            name: "#USP",
                            component: ""
                        },
                        {
                            name: "#Channels",
                            component: ""
                        },
                        {
                            name: "#Adv",
                            component: ""
                        },
                        {
                            name: "#Finance",
                            component: ""
                        }, 
                        {
                            name: "#Businessdevelopment",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "NYMLAB",
                        component: ""
                    },
                    url: "https://www.nymlab.it",
                    subtitle: [
                        {
                            name: "#Brand image",
                            component: ""
                        },
                        {
                            name: "#UX/UI Design",
                            component: ""
                        },
                        {
                            name: "#Web-site development",
                            component: ""
                        },
                        {
                            name: "#IPr advisory",
                            component: ""
                        }
                    ]
                },
                {
                    title:{
                        name: "Kliveer",
                        component: ""
                    },
                    url: "https://www.kliveer.com/",
                    subtitle:[
                        {
                            name:"#PPKFormula",
                            component: ""
                        },
                        {
                            name:"#Strategy",
                            component: ""
                        },
                        {
                            name:"#USP",
                            component: ""
                        },
                        {
                            name:"#Channels",
                            component: ""
                        },
                        {
                            name:"#Adv",
                            component: ""
                        },
                        {
                            name:"#Finance",
                            component: ""
                        }, 
                        {
                            name:"#Businessdevelopment",
                            component: ""
                        }
                    ]
                },
                {
                    title:{
                        name: "Solido",
                        component: ""
                    },
                    url: "https://www.solidoplus.com/it/",
                    subtitle:[
                        {
                            name:"#PPKFormula",
                            component: ""
                        },
                        {
                            name:"#Strategy",
                            component: ""
                        },
                        {
                            name:"#USP",
                            component: ""
                        },
                        {
                            name:"#Channels",
                            component: ""
                        },
                        {
                            name:"#Adv",
                            component: ""
                        },
                        {
                            name:"#Finance",
                            component: ""
                        }, 
                        {
                            name:"#Businessdevelopment",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "Xeel",
                        component: ""
                    },
                    url: "https://xeel.tech/",
                    subtitle: [
                        {
                            name: "#business development",
                            component: ""
                        },
                        {
                            name: "#strategy",
                            component: ""
                        },
                        {
                            name: "#digital marketing",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "Undercontrol",
                        component: ""
                    },
                    url: "https://www.undercontrol.cloud/",
                    subtitle: [
                        {
                            name:"#PPKFormula",
                            component: ""
                        },
                        {
                            name:"#Strategy",
                            component: ""
                        },
                        {
                            name:"#USP",
                            component: ""
                        },
                        {
                            name:"#Channels",
                            component: ""
                        },
                        {
                            name:"#Adv",
                            component: ""
                        },
                        {
                            name:"#Finance",
                            component: ""
                        }, 
                        {
                            name:"#Businessdevelopment",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "Overzoom",
                        component: ""
                    },
                    url: "https://overzoom.it/",
                    subtitle: [
                        {
                            name: "#business Development",
                            component: ""
                        },
                        {
                            name: "#Strategy",
                            component: ""
                        },
                        {
                            name: "#Digital marketing",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "Laf School",
                        component: ""
                    },
                    url: "https://www.lafschool.it/",
                    subtitle: [
                        {
                            name:"#PPKFormula",
                            component: ""
                        },
                        {
                            name:"#Strategy",
                            component: ""
                        },
                        {
                            name:"#USP",
                            component: ""
                        },
                        {
                            name:"#Channels",
                            component: ""
                        },
                        {
                            name:"#Adv",
                            component: ""
                        },
                        {
                            name:"#Finance",
                            component: ""
                        }, 
                        {
                            name:"#Businessdevelopment",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "9PM",
                        component: ""
                    },
                    url: "https://9pm.it/",
                    subtitle: [
                        {
                            name: "#Brand image",
                            component: ""
                        },
                        {
                            name: "#IPr advisory",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "Open Credits",
                        component: ""
                    },
                    url: "",
                    subtitle: [
                        {
                            name:"#PPKFormula",
                            component: ""
                        },
                        {
                            name:"#Strategy",
                            component: ""
                        },
                        {
                            name:"#USP",
                            component: ""
                        },
                        {
                            name:"#Channels",
                            component: ""
                        },
                        {
                            name:"#Adv",
                            component: ""
                        },
                        {
                            name:"#Finance",
                            component: ""
                        }, 
                        {
                            name:"#Businessdevelopment",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "N10 Holding",
                        component: ""
                    },
                    url: "",
                    subtitle: [
                        {
                            name: "#Management",
                            component: ""
                        },
                        {
                            name: "#Business design",
                            component: ""
                        },
                        {
                            name: "#Brand image",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "Redbee",
                        component: ""
                    },
                    url: "https://redbee.it/",
                    subtitle: [
                        {
                            name:"#PPKFormula",
                            component: ""
                        },
                        {
                            name:"#Strategy",
                            component: ""
                        },
                        {
                            name:"#USP",
                            component: ""
                        },
                        {
                            name:"#Channels",
                            component: ""
                        },
                        {
                            name:"#Adv",
                            component: ""
                        },
                        {
                            name:"#Finance",
                            component: ""
                        }, 
                        {
                            name:"#Businessdevelopment",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "Il Dato",
                        component: ""
                    },
                    url: "https://www.ildato.it/",
                    subtitle: [
                        {
                            name: "#Strategy",
                            component: ""
                        },
                        {
                            name: "#Businessdevelopment",
                            component: ""
                        },
                        {
                            name: "#Growth",
                            component: ""
                        },
                        {
                            name: "#Ads",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "WinWork Gold",
                        component: ""
                    },
                    url: "https://www.winwork-gold.it/",
                    subtitle: [
                        {
                            name: "#Strategy",
                            component: ""
                        },
                        {
                            name: "#Businessdevelopment",
                            component: ""
                        },
                        {
                            name: "#Growth",
                            component: ""
                        },
                        {
                            name: "#Ads",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "C95",
                        component: ""
                    },
                    url: "",
                    subtitle: [
                        {
                            name: "#Strategy",
                            component: ""
                        },
                        {
                            name: "#Businessdevelopment",
                            component: ""
                        },
                        {
                            name: "#Growth",
                            component: ""
                        },
                        {
                            name: "#Ads",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "Facto3D",
                        component: ""
                    },
                    url: "https://www.facto3d.it/",
                    subtitle: [
                        {
                            name:"#PPKFormula",
                            component: ""
                        },
                        {
                            name:"#Strategy",
                            component: ""
                        },
                        {
                            name:"#USP",
                            component: ""
                        },
                        {
                            name:"#Channels",
                            component: ""
                        },
                        {
                            name:"#Adv",
                            component: ""
                        },
                        {
                            name:"#Finance",
                            component: ""
                        }, 
                        {
                            name:"#Businessdevelopment",
                            component: ""
                        }
                    ]
                },
                {
                    title: {
                        name: "Weiji",
                        component: ""
                    },
                    url: "https://weiji.it/",
                    subtitle: [
                        {
                            name: "#Strategy",
                            component: ""
                        },
                        {
                            name: "#Businessdesign",
                            component: ""
                        },
                        {
                            name: "#Productmanagement",
                            component: ""
                        },
                        {
                            name: "#Growth",
                            component: ""
                        },
                        {
                            name: "#Ads",
                            component: ""
                        }
                    ]
                },
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.project-title-box {
    margin-bottom: 40px;
}

.project-subtitle {
    margin: 0;
}
</style>